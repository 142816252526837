import { Router } from "@angular/router";
import { LocalStorageService } from "./../../../../shared/services/local-storage/local-storage.service";
import { Component, OnInit } from "@angular/core";
import * as $ from "jquery";
import { environment } from "@env/environment";
import { LayoutService } from "../../services/layout.service";
// import { LoginService } from "@app/modules/auth/login/services/login.service";
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
import { ManageTeacherService } from "@app/modules/manage-teacher/services/manage-teacher.service";
import { StudentListService } from "@app/modules/manage-student/student-list/services/student-list.service";
import { UserProfileComponent } from "@app/modules/user-profile/components/user-profile.component";
import { UserProfileService } from "@app/modules/user-profile/services/user-profile.service";
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
})
export class SidebarComponent implements OnInit {
  defaultImagePath = environment.online_test_defaultImagePath;
  imageUrl = environment.online_test_baseImageUrl;
  baseUrl = environment.online_test_baseIP;
  userDetail: any;
  constructor(
    private layoutService: LayoutService,
    private localStorageService: LocalStorageService, // private loginService: LoginService,
    private router: Router,
    private manageTeacherService: ManageTeacherService,
    private location: Location,
    private studentListService: StudentListService,
    private userProfileService: UserProfileService
  ) {}

  user_type = this.localStorageService.getLocalStorageItem("user_type");
  user_id = this.localStorageService.getLocalStorageItem("user_id");
  level1User = this.localStorageService.getLocalStorageItem("level1User");
  level2User = this.localStorageService.getLocalStorageItem("level2User");
  loggedInAs = this.localStorageService.getLocalStorageItem("loggedInFrom");
  admin_id = this.localStorageService.getLocalStorageItem("admin_id");
  photo = this.localStorageService.getLocalStorageItem("user_image");
  syncSimpleBackLink: any;
  navItems: any;
  adminNavItems = [
    {
      id: 1,
      displayName: "Dashboard",
      iconName: "fa fa-dashboard",

      children: [
        {
          displayName: "Admin Dashboard",
          iconName: "fa fa-dashboard",
          route: "dashboard/admin",
        },
      ],
    },
    {
      id: 2,
      displayName: "Extra Utilities",
      // displayName: "Survey",

      iconName: "fa fa-book",

      children: [
        {
          displayName: "LRM",
          iconName: "fa fa-book",
          route: "lrm",
        },
        {
          displayName: "User Manual",
          iconName: "fa fa-book",
          route: "user-manual",
        },
        {
          displayName: "Course Syllabus",
          iconName: "fa fa-book",
          route: "syllabus",
        },
      ],
    },

    {
      id: 3,
      displayName: "Courses",
      // displayName: "Survey",

      iconName: "fa fa-book",

      children: [
        {
          displayName: "Course Faculty",
          iconName: "fa fa-book",
          route: "course-faculty",
        },
        {
          displayName: "Courses",
          iconName: "fa fa-book",
          route: "courses",
        },
        {
          displayName: "Course Attachments",
          iconName: "fa fa-file-pdf-o",
          route: "course-attachment",
        },
        // {
        //   displayName: "Course Session",
        //   iconName: "fa  fa-list-ol",
        //   route: "course-session",
        // },
      ],
    },

    {
      id: 4,
      displayName: "Exams",
      // displayName: "Create Survey",
      iconName: "fa fa-pencil",
      children: [
        {
          displayName: "Exams",
          iconName: "fa fa-book",
          route: "exams",
        },
        {
          displayName: "Exam Level",
          iconName: "fa fa-book",
          route: "exams/level",
        },
        // {
        //   displayName: "Course Session",
        //   iconName: "fa  fa-list-ol",
        //   route: "course-session",
        // },
      ],
    },

    // {
    //   id: 6,
    //   displayName: "Survey",
    //   iconName: "fa fa-tasks",

    //   children: [
    //     {
    //       displayName: "Survey Group",
    //       iconName: "fa fa-tasks",
    //       route: "survey-group",
    //     },
    //     {
    //       displayName: "Survey Schedule",
    //       iconName: "fa fa-tasks",
    //       route: "survey",
    //     },
    //   ],
    // },

    {
      id: 2,
      displayName: "Manage " + this.level1User,
      iconName: "fa  fa-user",
      children: [
        {
          displayName: this.level1User + " list",
          iconName: "fa  fa-user",
          route: "manage-teacher",
        },
      ],
    },
    {
      id: 6,
      displayName: "Manage " + this.level2User,
      iconName: "fa  fa-user",

      children: [
        {
          displayName: this.level2User + " list",
          iconName: "fa  fa-user",
          route: "student-list",
        },
        // {
        //   displayName: this.level2User + " Enrolled",
        //   iconName: "fa fa-dashboard",
        //   route: "student-enrolled",
        // },
      ],
    },
    // {
    //   id: 4,
    //   displayName: "Survey Report",
    //   iconName: "fa fa-file-text-o",
    //   route: "report",
    //   children: [
    //     {
    //       displayName: "Tabular Reports",
    //       iconName: "fa fa-file-text",
    //       route: "report",
    //     },
    //     {
    //       displayName: "Summarized Report",
    //       iconName: "fa fa-file-text",
    //       route: "report/answer",
    //     },
    //   ],
    // },
    {
      id: 8,
      displayName: "Exam Report",
      iconName: "fa  fa-file-text",
      route: "report/exam",
    },
    {
      id: 10,
      displayName: "Admissions",
      iconName: "fa fa-file-text-o",
      route: "admissions",
    },
    {
      id: 12,
      displayName: "Payments",
      iconName: "fa fa-money",
      route: "payments",
    },
    {
      id: 11,
      displayName: "Feedbacks",
      iconName: "fa fa-thumbs-up",
      route: "feedbacks",
    },
    {
      id: 9,
      displayName: "Discussion Forum",
      iconName: "fa fa-users",
      route: "forum",
    },
    {
      id: 10,
      displayName: "Notice",
      iconName: "fa fa-bell",
      route: "notice",
    },
    {
      id: 11,
      displayName: "SMS",
      iconName: "fa fa-envelope",
      route: "sms",
    },
    {
      id: 12,
      displayName: "Advertisement",
      iconName: "fa fa-file-text-o",
      route: "advertisement",
    },
    {
      id: 7,
      displayName: "Settings",
      iconName: "fa fa-gear",
      route: "settings/list-dynamic-page",
    },
  ];

  superAdminNavItems = [
    {
      id: 1,
      displayName: "Dashboard",
      iconName: "fa fa-dashboard",

      children: [
        {
          displayName: "Admin Dashboard",
          iconName: "fa fa-dashboard",
          route: "dashboard/admin",
        },
      ],
    },

    {
      id: 3,
      displayName: "Courses",
      // displayName: "Survey",

      iconName: "fa fa-book",

      children: [
        {
          displayName: "Course Faculty",
          iconName: "fa fa-book",
          route: "course-faculty",
        },
        {
          displayName: "Courses",
          iconName: "fa fa-book",
          route: "courses",
        },
        {
          displayName: "Course Attachments",
          iconName: "fa fa-file-pdf-o",
          route: "course-attachment",
        },
        // {
        //   displayName: "Course Session",
        //   iconName: "fa  fa-list-ol",
        //   route: "course-session",
        // },
      ],
    },

    {
      id: 4,
      displayName: "Exams",
      // displayName: "Create Survey",
      iconName: "fa fa-pencil",
      route: "exams",
    },

    // {
    //   id: 6,
    //   displayName: "Survey",
    //   iconName: "fa fa-tasks",

    //   children: [
    //     {
    //       displayName: "Survey Group",
    //       iconName: "fa fa-tasks",
    //       route: "survey-group",
    //     },
    //     {
    //       displayName: "Survey Schedule",
    //       iconName: "fa fa-tasks",
    //       route: "survey",
    //     },
    //   ],
    // },

    {
      id: 2,
      displayName: "Manage " + this.level1User,
      iconName: "fa  fa-user",
      route: "manage-teacher",
    },
    {
      id: 6,
      displayName: "Manage " + this.level2User,
      iconName: "fa  fa-user",

      children: [
        {
          displayName: this.level2User + " list",
          iconName: "fa  fa-user",
          route: "student-list",
        },
        // {
        //   displayName: this.level2User + " Enrolled",
        //   iconName: "fa fa-dashboard",
        //   route: "student-enrolled",
        // },
      ],
    },
    // {
    //   id: 4,
    //   displayName: "Survey Report",
    //   iconName: "fa fa-file-text-o",
    //   route: "report",
    //   children: [
    //     {
    //       displayName: "Tabular Reports",
    //       iconName: "fa fa-file-text",
    //       route: "report",
    //     },
    //     {
    //       displayName: "Summarized Report",
    //       iconName: "fa fa-file-text",
    //       route: "report/answer",
    //     },
    //   ],
    // },
    {
      id: 8,
      displayName: "Exam Report",
      iconName: "fa  fa-file-text",
      route: "report/exam",
    },
    {
      id: 10,
      displayName: "Admissions",
      iconName: "fa fa-file-text-o",
      route: "admissions",
    },
    {
      id: 12,
      displayName: "Payments",
      iconName: "fas fa-money-check",
      route: "payments",
    },
    {
      id: 11,
      displayName: "Feedbacks",
      iconName: "fa fa-thumbs-up",
      route: "feedbacks",
    },
    {
      id: 9,
      displayName: "Discussion forum",
      iconName: "fa fa-users",
      route: "forum",
    },
    {
      id: 10,
      displayName: "Notice",
      iconName: "fa fa-bell",
      route: "notice",
    },
    {
      id: 11,
      displayName: "SMS",
      iconName: "fa fa-envelope",
      route: "sms",
    },
    {
      id: 7,
      displayName: "Settings",
      iconName: "fa fa-gear",
      route: "settings",
    },
  ];

  syncSimpleAdminNavItems = [
    {
      id: 1,
      displayName: "Dashboard",
      iconName: "fa fa-dashboard",

      children: [
        {
          displayName: "Admin Dashboard",
          iconName: "fa fa-dashboard",
          route: "dashboard/admin",
        },
      ],
    },

    {
      id: 3,
      displayName: "Courses",
      // displayName: "Survey",

      iconName: "fa fa-book",

      children: [
        // {
        //   displayName: "Course Faculty",
        //   iconName: "fa fa-dashboard",
        //   route: "course-faculty",
        // },
        {
          displayName: "Courses",
          iconName: "fa fa-book",
          route: "courses",
        },
        {
          displayName: "Course Attachments",
          iconName: "fa fa-file-pdf-o",
          route: "course-attachment",
        },
        // {
        //   displayName: "Course Session",
        //   iconName: "fa  fa-list-ol",
        //   route: "course-session",
        // },
        {
          displayName: "Session Certificate",
          iconName: "fa   fa-check-circle",
          route: "session-certificate",
        },
      ],
    },

    {
      id: 4,
      displayName: "Exams",

      iconName: "fa fa-pencil",
      route: "exams",
    },

    {
      id: 6,
      displayName: "Survey",
      iconName: "fa fa-tasks",

      children: [
        {
          displayName: "Survey Group",
          iconName: "fa fa-tasks",
          route: "survey-group",
        },
        {
          displayName: "Survey Schedule",
          iconName: "fa fa-tasks",
          route: "survey",
        },
      ],
    },

    {
      id: 2,
      displayName: "Manage " + this.level1User,
      iconName: "fa  fa-user",
      route: "manage-teacher",
    },
    {
      id: 6,
      displayName: "Manage " + this.level2User,
      iconName: "fa  fa-user",

      children: [
        {
          displayName: this.level2User + " list",
          iconName: "fa  fa-user",
          route: "student-list",
        },
      ],
    },
    {
      id: 4,
      displayName: "Survey Report",
      iconName: "fa fa-file-text-o",
      route: "report",
      children: [
        {
          displayName: "Tabular Reports",
          iconName: "fa fa-file-text",
          route: "report",
        },
        {
          displayName: "Summarized Report",
          iconName: "fa fa-file-text",
          route: "report/answer",
        },
      ],
    },
    // {
    //   id: 8,
    //   displayName: "Exam Report",
    //   iconName: "fa fa-dashboard",
    //   route: "report/exam",
    // },

    {
      id: 7,
      displayName: "Settings",
      iconName: "fa fa-gear",
      route: "settings",
    },
  ];

  teacherNavItems = [
    {
      id: 1,
      displayName: "Dashboard",
      iconName: "fa fa-dashboard",

      children: [
        {
          displayName: this.level1User + " Dashboard",
          iconName: "fa fa-dashboard",
          route: "dashboard/teacher",
        },
      ],
    },
    {
      id: 2,
      displayName: "Courses",
      iconName: "fa fa-book",

      children: [
        // {
        //   displayName: "Course Faculty",
        //   iconName: "fa fa-book",
        //   route: "course-faculty",
        // },
        {
          displayName: "Course List",
          iconName: "fa fa-book",
          route: "courses",
        },
        {
          displayName: "Course Attachments",
          iconName: "fa fa-file-pdf-o",
          route: "course-attachment",
        },
        // {
        //   displayName: "Course Session",
        //   iconName: "fa  fa-list-ol",
        //   route: "course-session",
        // },
      ],
    },

    {
      id: 4,
      displayName: "Exams",
      iconName: "fa fa-pencil",
      route: "exams",
    },

    {
      id: 6,
      displayName: "Manage " + this.level2User,
      iconName: "fa  fa-user",

      children: [
        {
          displayName: this.level2User + " list",
          iconName: "fa  fa-user",
          route: "student-list",
        },
        // {
        //   displayName: this.level2User + " Enrolled",
        //   iconName: "fa fa-dashboard",
        //   route: "student-enrolled",
        // },
      ],
    },
    {
      id: 8,
      displayName: "Exam Report",
      iconName: "fa  fa-file-text",
      route: "report/exam",
    },
    {
      id: 9,
      displayName: "Discussion forum",
      iconName: "fa fa-users",
      route: "forum",
    },
    // {
    //   id: 7,
    //   displayName: "Settings",
    //   iconName: "fa fa-gear",
    //   route: "settings",
    // },
  ];

  studentNavItems = [
    {
      id: 1,
      displayName: "Dashboard",
      iconName: "fa fa-dashboard",

      children: [
        {
          displayName: this.level2User + " Dashboard",
          iconName: "fa fa-dashboard",
          route: "dashboard/student",
        },
      ],
    },
    {
      id: 2,
      displayName: "Enrollment",
      iconName: "fa fa-dashboard",

      children: [
        {
          displayName: "Course List",
          iconName: "fa fa-book",
          route: "course-list",
        },
        {
          displayName: "Course Enrollment",
          iconName: "fa fa-book",
          route: "course-enrollment",
        },
        // {
        //   displayName: "Course Session Enrollment",
        //   iconName: "fa  fa-list-ol",
        //   route: "session-enrollment",
        // },
        {
          displayName: "Exam List",
          iconName: "fa fa-pencil",
          route: "exam-list",
        },
        {
          displayName: "Exam Enrollment",
          iconName: "fa fa-pencil",
          route: "exam-enrollment",
        },

        // {
        //   displayName: "Survey Enrollment",
        //   iconName: "fa fa-tasks",
        //   route: "survey-enrollment",
        // },
      ],
    },
    {
      id: 3,
      displayName: "Exam Report",
      iconName: "fa  fa-file-text",
      route: "report/exam",
    },
    {
      id: 9,
      displayName: "Discussion forum",
      iconName: "fa fa-users",
      route: "forum",
    },
    {
      id: 10,
      displayName: "Course Syllabus",
      iconName: "fa fa-book",
      route: "course-syllabus",
    },
    {
      id: 9,
      displayName: "User Manual",
      iconName: "fa fa-book",
      route: "user-manual/view/1",
    },
  ];

  syncSimpleStudentNavItems = [
    {
      id: 1,
      displayName: "Dashboard",
      iconName: "fa fa-dashboard",

      children: [
        {
          displayName: this.level2User + " Dashboard",
          iconName: "fa fa-dashboard",
          route: "dashboard/student",
        },
      ],
    },
    // {
    //   id: 2,
    //   displayName: "Enrollment",
    //   iconName: "fa fa-dashboard",

    //   children: [
    //     {
    //       displayName: "Course Enrollment",
    //       iconName: "fa fa-dashboard",
    //       route: "course-enrollment",
    //     },
    //     {
    //       displayName: "Course Session Enrollment",
    //       iconName: "fa  fa-list-ol",
    //       route: "session-enrollment",
    //     },

    //     {
    //       displayName: "Survey Enrollment",
    //       iconName: "fa fa-tasks",
    //       route: "survey-enrollment",
    //     },

    //     {
    //       displayName: "Exam Enrollment",
    //       iconName: "fa fa-pencil",
    //       route: "exam-enrollment",
    //     },
    //   ],
    // },
  ];

  // this.user_type == "student" ? this.studentNavItems : this.teacherNavItems;

  ngOnInit() {
    console.log(this.imageUrl + this.photo, "fotoo");
    this.setNavItems();
    if (this.user_type == "teacher") {
      this.getTeachersList();
    } else if (this.user_type == "student") {
      this.getStudentList();
    } else {
      this.getAdminDetail();
    }
  }

  setNavItems() {
    if (this.user_type == "student") {
      if (this.loggedInAs == "syncSimple") {
        this.navItems = this.syncSimpleStudentNavItems;
        this.syncSimpleBackLink = "https://sync.tcaportal.net/affemployee";
      } else {
        this.navItems = this.studentNavItems;
      }
    } else if (this.user_type == "teacher") {
      if (this.loggedInAs == "syncSimple") {
        this.syncSimpleBackLink = "https://sync.tcaportal.net/affcompany";
      }
      this.navItems = this.teacherNavItems;
    } else if ((this.user_type = "admin")) {
      if (this.loggedInAs == "syncSimple") {
        this.navItems = this.syncSimpleAdminNavItems;
        this.syncSimpleBackLink = "https://sync.tcaportal.net/affcompany";
      } else if (this.admin_id == 3) {
        this.navItems = this.superAdminNavItems;
      } else {
        this.navItems = this.adminNavItems;
      }
    }
  }

  userName: string;
  userInfo: any;
  getUserInfo() {
    // this.userInfo = this.loginService.getUserInfoFromStorage();
    // this.userName = this.layoutService.getUserFullName();
  }

  getTeachersList() {
    let body = {
      page: 1,
      limit: "",
      sort: 1,
      sort_field: "name",
      fields: [
        {
          field: "id",
          operator: "contains",
          value: this.user_id,
        },
      ],
    };
    this.manageTeacherService.getTeachersList(body).subscribe((response) => {
      if (response.status == "success") {
        this.userInfo = response.data[0];
      }
    });
  }

  getStudentList(): void {
    let body = {
      mentor_id: "",
      student_id: this.user_id,
      page: 1,
      limit: "",
      sort: 1,
      sort_field: "name",
      fields: [
        {
          field: "id",
          operator: "matches",
          value: this.user_id,
        },
      ],
    };

    this.studentListService.getStudentList(body).subscribe((response) => {
      if (response.status == "success") {
        this.userInfo = response.data[0];
        return;
      }
    });
  }

  getAdminDetail(): void {
    let body = {
      page: 1,
      limit: "",
      sort: "",
      sort_field: "",
      fields: [
        {
          field: "id",
          operator: "contains",
          value: this.admin_id,
        },
      ],
    };

    this.userProfileService.getAdminDetail(body).subscribe(
      (response) => {
        if (response.status == "success") {
          this.userDetail = response.data[0].user_detail;
          return;
        }
      },
      (error) => {}
    );
  }

  toogleSideNav() {
    $("body").toggleClass("sidebar-toggled");
    $(".sidebar").toggleClass("toggled");
  }
}
