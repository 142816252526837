import { Injectable } from "@angular/core";
import { HttpParams, HttpHeaders, HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { LocalStorageService } from "@app/shared/services/local-storage/local-storage.service";
import { CookieService } from "ngx-cookie-service";
import { environment } from "@env/environment";
@Injectable({
  providedIn: "root",
})
export class SessionEnrollmentService {
  baseIp = environment.online_test_baseIP;
  apiPrefix = environment.online_test_apiPrefix;

  constructor(
    private localStorageService: LocalStorageService,
    private cookieService: CookieService,
    private httpClient: HttpClient
  ) {}

  access_token = this.cookieService.get("token");

  getCourseSessionList(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "access-token": this.access_token,
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}course-sequence-enroll/list-session`,
      body,
      options
    );
  }

  enrollIntoCourseSession(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "access-token": this.access_token,
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}course-sequence-enroll/create-enroll`,
      body,
      options
    );
  }

  getCourseSessionEnrolledList(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "access-token": this.access_token,
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}course-sequence-enroll/get-enroll`,
      body,
      options
    );
  }

  setAttachmentWatched(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "access-token": this.access_token,
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}course-sequence-enroll/mark-enroll`,
      body,
      options
    );
  }

  uploadSessionSignature(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "access-token": this.access_token,
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}student-session-result/create`,
      body,
      options
    );
  }

  uploadSignatureForAllSession(body): Observable<any>
  {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "access-token": this.access_token,
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}student-session-result/add-signature`,
      body,
      options
    );
  }

  getFontList(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "api-key": "KEY012345"
      }),
    };

    return this.httpClient.get(
      `${this.baseIp}${this.apiPrefix}signature/font-list`,
      options
    );
  }
}
