import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Input,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { StudentDashboardService } from "@app/modules/dashboard/student-dashboard/services/student-dashboard.service";
import { SessionEnrollmentService } from "@app/modules/enrollment/course-session-enrollment/services/session-enrollment.service";

@Component({
  selector: "dms-text-signature",
  templateUrl: "./text-signature.component.html",
  styleUrls: ["./text-signature.component.scss"],
})
export class TextSignatureComponent implements OnInit {
  canvas;
  context;
  @Output() onSaveSignature = new EventEmitter();
  @Output() generateFont = new EventEmitter();
  @Output() generateText = new EventEmitter();

  @Input() imgURL;
  textSignatureForm: FormGroup;
  @ViewChild("signPad", { static: true }) signPad;
  fontList: any[];
  selectedFont: string;
  imgLoaded: boolean = false;
  text: string;

  constructor(
    private formBuilder: FormBuilder,
    private studentDashboardService: StudentDashboardService,
    private sessionEnrollmentService: SessionEnrollmentService
  ) {}

  ngOnInit() {
    this.getFontList();
    this.buildTextSignatureForm();
  }

  buildTextSignatureForm() {
    this.textSignatureForm = this.formBuilder.group({
      signature_text: ["",Validators.required],
      signature_font: "",
    });
  }

  getFontList() {
    this.studentDashboardService.getFontList().subscribe((response) => {
      this.fontList = response.data;
      this.textSignatureForm.get("signature_font").patchValue(this.fontList[0]);
      this.selectedFont = this.textSignatureForm.value.signature_font;
    });
  }

  previewSignature(event) {
    this.selectedFont = event.target.value;
    let object = {
      text: this.textSignatureForm.value.signature_text,
      font: this.selectedFont,
    };
    this.studentDashboardService
      .generateFontSign(object)
      .subscribe((response) => {
        this.imgURL = response.data.base64;
        if (this.imgURL) {
          this.imgLoaded = true;
        }
      });
  }

  changeText(event) {
    this.text = event.target.value;
    let object = {
      text: event.target.value,
      font: this.selectedFont,
    };
    this.studentDashboardService
      .generateFontSign(object)
      .subscribe((response) => {
        this.imgURL = response.data.base64;
        if (this.imgURL) {
          this.imgLoaded = true;
        }
      });
  }

  img: any;
  onSave() {
    this.onSaveSignature.emit(this.imgURL);
  }
}
