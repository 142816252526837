import { LayoutService } from "./../../../services/layout.service";
import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { Router } from "@angular/router";
declare var $: any;
@Component({
  selector: "flexyear-menu-item",
  templateUrl: "./menu-item.component.html",
  styleUrls: ["./menu-item.component.scss"],
})
export class MenuItemComponent implements OnInit {
  @Input() navItems;

  expanded: boolean;
  term: any;
  constructor(private router: Router, private layoutService: LayoutService) {
  }

  ngOnInit() {
    $(document).ready(() => {
      const trees: any = $('[data-widget="tree"]');
      if (trees) {
        trees.tree();
      }
    });

    console.log(this.navItems,"navitems")
  }

  setExpandedTrue(event) {
    if (event.target.value != "") {
      this.expanded = true;
    } else {
      this.expanded = false;
    }
  }

  setTitle(title) {
    // this.layoutService.setSideBarTitle(this.router.url);
    this.layoutService.setContentTitle(title);
    // console.log(title);
  }
  // onItemSelect(item) {
  //   if (!item.children || !item.children.length) {
  //     this.router.navigate([item.route]);
  //   }

  //   if (item.children && item.children.length) {
  //     this.expanded = !this.expanded;
  //   }
  // }
}
