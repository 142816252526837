import { GlobalConstants } from "./constants";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";

import { environment } from "@env/environment.prod";
import { Observable } from "rxjs";

import { LocalStorageService } from "../local-storage/local-storage.service";
import { CookieService } from "ngx-cookie-service";

declare var require: any;
// var adbs = require("ad-bs-converter");
@Injectable({
  providedIn: "root",
})
export class GlobalService {
  baseIp = environment.online_test_baseIP;
  apiPrefix = environment.online_test_apiPrefix;
  accessToken = this.getAccessTokenFromCookie();
  pagelimit = 10;
  pageNumber = "1";

  constructor(
    private localStorageService: LocalStorageService,
    private cookieService: CookieService,
    private datePipe: DatePipe,
    private router: Router
  ) {}

  getAccessLevelFromStorage() {
    const accessLevel = this.cookieService.get("access_level");
    return accessLevel;
  }

  getCompanyIdFromStorage() {
    const userInfo = this.localStorageService.getLocalStorageItem("user_info");
    const companyId = userInfo ? userInfo.company_id : "3";
    return companyId;
  }

  getAccessTokenFromCookie() {
    const accessToken = this.cookieService.get("token");
    return accessToken;
  }

  logout() {
    this.cookieService.deleteAll();
    this.localStorageService.clearLocalStorage();
    this.router.navigate(["home/login"]);
  }

  countryList = GlobalConstants.countryList;
  timeZone = GlobalConstants.timeZone;

  englishMonth = GlobalConstants.englishMonth;
  nepaliMonth = GlobalConstants.nepaliMonth;
  englishYear = GlobalConstants.englishYear;
  nepaliYear = GlobalConstants.NepaliYear;
}
