import { Injectable } from "@angular/core";
import { HttpParams, HttpHeaders, HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { LocalStorageService } from "@app/shared/services/local-storage/local-storage.service";
import { CookieService } from "ngx-cookie-service";
import { environment } from "@env/environment";

@Injectable({
  providedIn: "root",
})
export class UserProfileService {
  baseIp = environment.online_test_baseIP;
  apiPrefix = environment.online_test_apiPrefix;

  constructor(
    private localStorageService: LocalStorageService,
    private cookieService: CookieService,
    private httpClient: HttpClient
  ) {}

  access_token = this.cookieService.get("token");

  endpoint = "mentor/reset-mentor-password";

  resetPassword(body, endPoint): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "access-token": this.access_token,
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}${endPoint}`,
      body,
      options
    );
  }

  getAdminDetail(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "access-token": this.access_token,
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}user/get-users`,
      body,
      options
    );
  }

  // update admin detail

  updateAdminDetail(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "access-token": this.access_token,
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}user/update-user`,
      body,
      options
    );
  }
}
