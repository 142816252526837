import { Injectable } from "@angular/core";

import { HttpParams, HttpHeaders, HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { LocalStorageService } from "@app/shared/services/local-storage/local-storage.service";
import { CookieService } from "ngx-cookie-service";
import { environment } from "@env/environment";
@Injectable({
  providedIn: "root",
})
export class StudentListService {
  baseIp = environment.online_test_baseIP;
  apiPrefix = environment.online_test_apiPrefix;
  access_token = this.cookieService.get("token");
  constructor(
    private localStorageService: LocalStorageService,
    private cookieService: CookieService,
    private httpClient: HttpClient
  ) {}

  getStudentList(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "access-token": this.access_token,
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}student/get-students`,
      body,
      options
    );
  }

  getStudentListByfaculty(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "access-token": this.access_token,
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}student/student-search`,
      body,
      options
    );
  }

  createStudent(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "access-token": this.access_token,
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}student/create-student`,
      body,
      options
    );
  }

  editStudent(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "access-token": this.access_token,
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}student/update-student`,
      body,
      options
    );
  }

  deleteStudent(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "access-token": this.access_token,
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}student/delete-student`,
      body,
      options
    );
  }

  exportStudent(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "access-token": this.access_token,
      }),
    };

    return this.httpClient.get(
      `${this.baseIp}${this.apiPrefix}student/export`,
      options
    );
  }
}
