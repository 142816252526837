import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  CollapseModule,
  TooltipModule,
  TabsModule,
  ModalModule,
  TimepickerModule,
  BsDropdownModule,
  BsDatepickerModule,
  PopoverModule,
} from "ngx-bootstrap";

// import { Ng2SearchPipeModule } from "ng2-search-filter";
// import { OrderModule } from "ngx-order-pipe";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// import { MatStepperModule } from "@angular/material/stepper";

// import { TextMaskModule } from "angular2-text-mask";
// import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";

// import { AvatarModule } from "ngx-avatar";

// validation directive.....
import { FormControlValidationMsgDirective } from "./directives/validators/validation-message.directive";
import { FormSubmitValidationMsgDirective } from "./directives/validators/submit-validation-msg.directive";
import { ValidationMsgService } from "./directives/validation-message.service";

// import { NpDatepickerModule } from "angular-nepali-datepicker";
// ..................kendo...............................
import {
  GridModule,
  PDFModule,
  ExcelModule,
} from "@progress/kendo-angular-grid";

import { UploadModule, UploadsModule } from "@progress/kendo-angular-upload";
// import { IntlModule } from "@progress/kendo-angular-intl";
// import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
// import { ExcelExportModule } from "@progress/kendo-angular-excel-export";

// import { ConfirmationDialogComponent } from "./components/confirmation-dialog/confirmation-dialog.component";

// ................kendo..........................
// ............shared components.............

import { NgSelectModule } from "@ng-select/ng-select";
import { NgxPaginationModule } from "ngx-pagination";
import { TextSignatureComponent } from "./components/signature-modal/text-signature/text-signature.component";
import { UploadSignatureComponent } from "./components/signature-modal/upload-signature/upload-signature.component";
import { DrawSignatureComponent } from "./components/signature-modal/draw-signature/draw-signature.component";
@NgModule({
  declarations: [
    FormControlValidationMsgDirective,
    FormSubmitValidationMsgDirective,
    DrawSignatureComponent,
    TextSignatureComponent,
    UploadSignatureComponent
    // ConfirmationDialogComponent,
  ],
  imports: [
    CommonModule,
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    NgxPaginationModule,
    // Ng2SearchPipeModule,
    // OrderModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    // MatStepperModule,
    // TextMaskModule,
    TimepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    // NgMultiSelectDropDownModule.forRoot(),
    // AvatarModule,
    // NpDatepickerModule,
    // ........kendo...........
    GridModule,
    PDFModule,
    ExcelModule,

    UploadModule,
    UploadsModule,
    // IntlModule,
    // DateInputsModule,
    // ExcelExportModule
    // ........kendo............
  ],
  exports: [
    // CollapseModule,
    // TooltipModule,
    TabsModule,
    // ModalModule,
    // PopoverModule,
    NgSelectModule,
    NgxPaginationModule,
    // Ng2SearchPipeModule,
    // OrderModule,
    FormsModule,
    ReactiveFormsModule,
    // MatStepperModule,
    // LanguageSwitcherComponent,
    // TextMaskModule,
    TimepickerModule,
    BsDatepickerModule,
    BsDropdownModule,
    // NgMultiSelectDropDownModule,
    // AvatarModule,
    // ConfirmationDialogComponent,
    FormControlValidationMsgDirective,
    FormSubmitValidationMsgDirective,
    // NpDatepickerModule,

    //....... kendo..........
    GridModule,
    PDFModule,
    ExcelModule,
    
    DrawSignatureComponent,
    TextSignatureComponent,
    UploadSignatureComponent,

    UploadModule,
    UploadsModule,
    // IntlModule,
    // DateInputsModule,
    // ExcelExportModule
    // ........................
  ],
  providers: [ValidationMsgService],
})
export class SharedModule {}
