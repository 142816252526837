import { Injectable } from "@angular/core";

import { HttpParams, HttpHeaders, HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { LocalStorageService } from "@app/shared/services/local-storage/local-storage.service";
import { CookieService } from "ngx-cookie-service";
import { environment } from "@env/environment";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  baseIp = environment.online_test_baseIP;
  apiPrefix = environment.online_test_apiPrefix;
  online_test_api_key = environment.online_test_api_key;
  companyId = 1;
  constructor(
    private localStorageService: LocalStorageService,
    private cookieService: CookieService,
    private httpClient: HttpClient
  ) {}

  // admin login
  adminLogin(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}user/login`,
      body,
      options
    );
  }

  // mentor sign up
  mentorSignUp(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "api-key": "KEY012346",
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}mentor/create-mentor`,
      body,
      options
    );
  }

  //  login with token for sync simple
  // here api key of sync simple is used
  adminLoginWithToken(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "api-key": "KEY012345",
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}user/token-login`,
      body,
      options
    );
  }

  studentLoginWithToken(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "api-key": "KEY012345",
      }),
    };
    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}student/student-login-by-key`,
      body,
      options
    );
  }

  // mentor login
  mentorLogin(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}mentor/mentor-login`,
      body,
      options
    );
  }

  // montor logout
  mentorLogout(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}mentor/mentor-logout`,
      body,
      options
    );
  }

  // forget password of mentor
  mentorForgetPassword(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}mentor/forget-mentor-password`,
      body,
      options
    );
  }

  // student signup
  studentSignUp(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "api-key": "KEY012346",
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}student/create-student`,
      body,
      options
    );
  }
  // student login
  studentLogin(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}student/student-login`,
      body,
      options
    );
  }

  // student logout
  studentLogout(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}student/student-logout`,
      body,
      options
    );
  }

  // forget password of student
  studentForgetPassword(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "api-key": "KEY012346",
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}student/forget-student-password`,
      body,
      options
    );
  }

  studentResetPassword(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "api-key": "KEY012346",
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}student/reset-student-password`,
      body,
      options
    );
  }

  // submit online application form
  submitAdmissionForm(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "api-key": "KEY012346",
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}admission/create`,
      body,
      options
    );
  }

  getSettings(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "api-key": "KEY012346",
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}setting/view-data?key=test`,
      null,
      options
    );
  }

  // get landing page
  getPage(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "api-key": "KEY012346",
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}page/view`,
      body,
      options
    );
  }

  // get trending courses
  getTrendingCourses(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "api-key": "KEY012346",
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}online-course/trending-courses`,
      body,
      options
    );
  }

  // get trending Exams
  getTrendingExams(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "api-key": "KEY012346",
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}exam/trending-exams`,
      body,
      options
    );
  }
  // list courses in admission form
  getCoursesInAdmissionForm(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "api-key": "KEY012346",
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}online-course/list-courses`,
      null,
      options
    );
  }

  // list exams in admission form
  getExamsInAdmissionForm(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "api-key": "KEY012346",
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}exam/list-exams`,
      body,
      options
    );
  }

  // enroll from home page
  enrollIntoCourse(body, access_token): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "access-token": access_token,
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}course-enroll/create-course-enroll`,
      body,
      options
    );
  }

  enrollIntoExam(body, access_token): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "access-token": access_token,
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}exam-enroll/create-exam-enroll`,
      body,
      options
    );
  }

  /**
   * Returns the credential from local storage.
   */
  getCredentialsFromStorage() {
    return this.localStorageService.getLocalStorageItem("credentials");
  }

  getTokenFromCookie() {
    return this.cookieService.get("token");
  }

  /**
   * Verifies whether the user is already logged in.
   */
  isLoggedIn(): boolean {
    const access_token = this.cookieService.get("token");
    if (access_token) {
      return true;
    }
    return false;
  }

  setUserInfoOnStorage(userInfo) {
    this.localStorageService.setLocalStorageItem("user_info", userInfo);
  }

  getUserInfoFromStorage() {
    return this.localStorageService.getLocalStorageItem("user_info");
  }
}
