import { ElementRef, HostListener, Input, ViewChild } from "@angular/core";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "dms-draw-signature",
  templateUrl: "./draw-signature.component.html",
  styleUrls: ["./draw-signature.component.scss"],
})
export class DrawSignatureComponent implements OnInit {
  @Input() name: string;
  @Output() onSaveSignature = new EventEmitter();
  @ViewChild("signPad", { static: true }) signPad;
  signPadElement;
  context;
  isDrawing = false;
  img;

  ngOnInit() {
    this.signPadElement = this.signPad.nativeElement;
    this.context = this.signPadElement.getContext("2d");
    this.context.strokeStyle = "#555";
  }

  @HostListener("document:mouseup", ["$event"])
  onMouseUp(e) {
    this.isDrawing = false;
  }

  onMouseDown(e) {
    this.isDrawing = true;
    const coords = this.relativeCoords(e);
    this.context.moveTo(coords.x, coords.y);
  }

  onMouseMove(e) {
    if (this.isDrawing) {
      const coords = this.relativeCoords(e);
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
    }
  }

  private relativeCoords(event) {
    const bounds = event.target.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    return { x: x, y: y };
  }

  clear() {
    this.context.clearRect(
      0,
      0,
      this.signPadElement.width,
      this.signPadElement.height
    );
    this.context.beginPath();
  }

  save() {
    this.img = this.signPadElement.toDataURL("image/png");
    this.onSaveSignature.emit(this.img);
    console.log(this.img);
  }
}
