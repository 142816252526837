import { Injectable } from "@angular/core";

import { Observable, Subject } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class LayoutService {
  private subject = new Subject<any>();
  constructor() {}

  userName: string;
  setUserFullName(fullName: string) {
    this.userName = fullName;
  }

  getUserFullName() {
    return this.userName;
  }
  setContentTitle(title: string) {
    this.subject.next({ text: title });
  }
  getContentTitle() {
    return this.subject.asObservable();
  }
}
