import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import {
  FileRestrictions,
  RemoveEvent,
  SelectEvent,
} from "@progress/kendo-angular-upload";

@Component({
  selector: "dms-upload-signature",
  templateUrl: "./upload-signature.component.html",
  styleUrls: ["./upload-signature.component.scss"],
})
export class UploadSignatureComponent implements OnInit {
  @Output() onSaveSignature = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  // public imagePreviews: any;
  // public uploadRestrictions: FileRestrictions = {
  //   allowedExtensions: [".jpg", ".png"],
  // };
  // public selectEventHandler(e: SelectEvent): void {
  // }

  onRemove(): void {
    this.url = null;
  }

  url: any;
  fileName: any;
  onFileSelect(event1): void {
    // file is selected
    if (event1.target.files && event1.target.files[0]) {
      var reader = new FileReader();
      this.fileName = event1.target.files[0].name;
      if (event1.target.files[0].type == "image/jpeg") {
        reader.readAsDataURL(event1.target.files[0]); // read file as data url
        reader.onload = (event2: any) => {
          // called once readAsDataURL is completed
          this.url = event2.target.result;

          // this.createCourseForm.patchValue({
          //   course_cover: this.url.replace("data:image/jpeg;base64,", ""),
          // });
        };
      } else {
        return;
      }
    }
  }

  save(): void {
    if (this.url) {
      this.onSaveSignature.emit(this.url);
    }
  }
}
