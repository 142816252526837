import { Injectable } from "@angular/core";

import { HttpParams, HttpHeaders, HttpClient } from "@angular/common/http";
import { LocalStorageService } from "@app/shared/services/local-storage/local-storage.service";
import { CookieService } from "ngx-cookie-service";
import { environment } from "@env/environment";
import { MentorsRootModel } from "../models/manage-teacher.model";
import { Observable } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class ManageTeacherService {
  baseIp = environment.online_test_baseIP;
  apiPrefix = environment.online_test_apiPrefix;
  online_test_api_key = environment.online_test_api_key;
  access_token = this.localStorageService.getLocalStorageItem("access_token");
  constructor(
    private localStorageService: LocalStorageService,
    private cookieService: CookieService,
    private httpClient: HttpClient
  ) {}

  getTeachersList(body): Observable<MentorsRootModel> {
    console.log(this.access_token, "access_token");
    if (this.access_token == null) {
      this.access_token = this.cookieService.get("token");
    }
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "access-token": this.access_token,
      }),
    };
    return this.httpClient.post<MentorsRootModel>(
      `${this.baseIp}${this.apiPrefix}mentor/get-mentors`,
      body,
      options
    );
    // return this.httpClient.post(
    //   `${this.baseIp}${this.apiPrefix}mentor/get-mentors`,
    //   body,
    //   options
    // );
  }

  addTeacher(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "access-token": this.access_token,
        "api-key": this.online_test_api_key,
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}mentor/create-mentor`,
      body,
      options
    );
  }
  editTeacher(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "access-token": this.access_token,
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}mentor/update-mentor`,
      body,
      options
    );
  }

  deleteTeacher(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "access-token": this.access_token,
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}mentor/delete-mentor`,
      body,
      options
    );
  }

  exportTeacher(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "access-token": this.access_token,
      }),
    };

    return this.httpClient.get(
      `${this.baseIp}${this.apiPrefix}mentor/export`,
      options
    );
  }
}
