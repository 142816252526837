import { LocalStorageService } from "./../../../../shared/services/local-storage/local-storage.service";
import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import * as $ from "jquery";
import { environment } from "@env/environment";
// import { LoginService } from "@app/modules/auth/login/services/login.service";
import { HelperService } from "@app/shared/services/helper/helper.service";
import { LayoutService } from "../../services/layout.service";
import { ManageTeacherService } from "@app/modules/manage-teacher/services/manage-teacher.service";
import { StudentListService } from "@app/modules/manage-student/student-list/services/student-list.service";
import { UserProfileService } from "@app/modules/user-profile/services/user-profile.service";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})
export class TopbarComponent implements OnInit {
  defaultImagePath = environment.online_test_defaultImagePath;
  imageUrl = environment.online_test_baseImageUrl;
  selectedLanguage: string;
  user_type = this.localStorageService.getLocalStorageItem("user_type");
  loggedInAs = this.localStorageService.getLocalStorageItem("loggedInFrom");
  user_id = this.localStorageService.getLocalStorageItem("user_id");
  admin_id = this.localStorageService.getLocalStorageItem("admin_id");
  userDetail: any;

  constructor(
    private router: Router,
    // private loginService: LoginService,
    private helperService: HelperService,
    private layoutService: LayoutService,
    private localStorageService: LocalStorageService,
    private manageTeacherService: ManageTeacherService,
    private studentListService: StudentListService,
    private userProfileService: UserProfileService
  ) {}
  navigateToDashboard() {
    if (this.user_type == "admin") {
      this.router.navigate(["/dashboard/admin"]);
    } else if (this.user_type == "teacher") {
      this.router.navigate(["/dashboard/teacher"]);
    } else {
      this.router.navigate(["/dashboard/student"]);
    }
  }

  navigateToUserProfile() {
    this.router.navigate(["/user-profile"]);
  }

  ngOnInit() {
    this.getUserInfo();
    if (this.user_type == "teacher") {
      this.getTeachersList();
    } else if (this.user_type == "student") {
      this.getStudentList();
    } else {
      this.getAdminDetail();
    }
  }
  userName: string;
  userInfo: any;

  getUserInfo() {
    this.userName = this.localStorageService.getLocalStorageItem("user_name");
    // this.getUserFullName();
  }

  getTeachersList() {
    let body = {
      page: 1,
      limit: "",
      sort: 1,
      sort_field: "name",
      fields: [
        {
          field: "id",
          operator: "contains",
          value: this.user_id,
        },
      ],
    };
    this.manageTeacherService.getTeachersList(body).subscribe((response) => {
      if (response.status == "success") {
        this.userInfo = response.data[0];
      }
    });
  }

  getStudentList(): void {
    let body = {
      mentor_id: "",
      student_id: this.user_id,
      page: 1,
      limit: "",
      sort: 1,
      sort_field: "name",
      fields: [
        {
          field: "id",
          operator: "matches",
          value: this.user_id,
        },
      ],
    };

    this.studentListService.getStudentList(body).subscribe((response) => {
      if (response.status == "success") {
        this.userInfo = response.data[0];
        return;
      }
    });
  }

  getAdminDetail(): void {
    let body = {
      page: 1,
      limit: "",
      sort: "",
      sort_field: "",
      fields: [
        {
          field: "id",
          operator: "contains",
          value: this.admin_id,
        },
      ],
    };

    this.userProfileService.getAdminDetail(body).subscribe(
      (response) => {
        if (response.status == "success") {
          this.userDetail = response.data[0].user_detail;
          console.log(this.imageUrl, this.userDetail, "imageeee");
          return;
        }
      },
      (error) => {}
    );
  }

  // getUserFullName() {
  //   if (this.userInfo) {
  //     this.userName = this.helperService.getFullName(this.userInfo);
  //   } else {
  //     this.userName = "Unknown";
  //   }
  //   this.layoutService.setUserFullName(this.userName);
  // }

  toogleSideNav() {
    $("body").toggleClass("sidebar-toggled");
    $(".sidebar").toggleClass("toggled");
  }
}
