import { TeacherGuard } from "./core/guards/teacher.guard";
import { StudentGuard } from "./core/guards/student.guard";
import { LocalStorageService } from "./shared/services/local-storage/local-storage.service";
import { AuthGuard } from "./core/guards/auth.guard";
import { AdminPanelComponent } from "./core/layout/admin-panel/admin-panel.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  {
    path: "home",
    loadChildren: "@modules/auth/login/login.module#LoginModule",
  },

  {
    path: "",
    component: AdminPanelComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: "Home",
    },
    children: [
      { path: "", redirectTo: "dashboard/admin", pathMatch: "full" },
      {
        path: "dashboard/admin",
        loadChildren:
          "@modules/dashboard/admin-dashboard/admin-dashboard.module#AdminDashboardModule",
        canActivate: [StudentGuard, TeacherGuard],
        data: {
          breadcrumb: "Admin Dashboard",
          tittleInformation: "Control Panel for Admin.",
        },
      },
      {
        path: "dashboard/student",
        loadChildren:
          "@modules/dashboard/student-dashboard/student.module#StudentModule",
        canActivate: [TeacherGuard],
        data: {
          breadcrumb: "Student Dashboard",
          tittleInformation: "Control Panel for Student",
        },
      },
      {
        path: "dashboard/teacher",
        loadChildren:
          "@modules/dashboard/teacher-dashboard/teacher.module#TeacherModule",
        canActivate: [StudentGuard],
        data: {
          breadcrumb: "Teacher Dashboard",
          tittleInformation: "Control Panel for Teacher",
        },
      },

      {
        path: "course-faculty",
        loadChildren:
          "@modules/course-faculty/course-faculty.module#CourseFacultyModule",
        canActivate: [StudentGuard],
        data: {
          breadcrumb: "Course Faculty",

          tittleInformation: "Create Courses",
        },
      },

      {
        path: "courses",
        loadChildren: "@modules/courses/courses.module#CoursesModule",
        canActivate: [StudentGuard],
        data: {
          breadcrumb: "Courses",

          tittleInformation: "Create Courses",
        },
      },

      {
        path: "lrm",
        loadChildren: "@modules/lrm/lrm.module#LrmModule",
        data: {
          breadcrumb: "LRM",

          tittleInformation: "LRM",
        },
      },

      {
        path: "survey-group",
        loadChildren:
          "@modules/survey/survey-group/survey-group.module#SurveyGroupModule",
        canActivate: [StudentGuard],
        data: {
          breadcrumb: "Survey Group",
          tittleInformation: "Create Survey Group",
        },
      },

      {
        path: "course-attachment",
        loadChildren:
          "@modules/course-attachments/course-attachment.module#CourseAttachmentModule",
        canActivate: [StudentGuard],
        data: {
          breadcrumb: "Course Attachments",
          tittleInformation: "Add course Attachments",
        },
      },
      {
        path: "course-session",
        loadChildren:
          "@modules/course-session/course-session.module#CourseSessionModule",
        canActivate: [StudentGuard],
        data: {
          breadcrumb: "Course Session",
          tittleInformation: "Create course Session",
        },
      },

      {
        path: "session-certificate",
        loadChildren:
          "@modules/session-certificates/certificate.module#CertificateModule",
        canActivate: [StudentGuard, TeacherGuard],
        data: {
          breadcrumb: "Session Certificate",
          tittleInformation: "View Session Certificates",
        },
      },

      {
        path: "exams",
        loadChildren: "@modules/exams/exam.module#ExamModule",
        canActivate: [StudentGuard],
        data: {
          breadcrumb: "Exams",
          tittleInformation: "Create Exam schedule",
        },
      },
      {
        path: "survey",
        loadChildren:
          "@modules/survey/survey-schedule/survey.module#SurveyModule",
        canActivate: [StudentGuard],
        data: {
          breadcrumb: "Create Survey",

          tittleInformation: "Create Survey schedule",
        },
      },
      {
        path: "course-enrollment",
        loadChildren:
          "@modules/enrollment/course-enrollment/course-enrollment.module#CourseEnrollmentModule",
        data: {
          breadcrumb: "Course Enrollment",
          tittleInformation: "Enroll into Course",
        },
      },
      {
        path: "course-list",
        loadChildren:
          "@modules/enrollment/course-list/course-list.module#CourseListModule",
        data: {
          breadcrumb: "Course List",
          tittleInformation: "List of Course",
        },
      },
      {
        path: "session-enrollment",
        loadChildren:
          "@modules/enrollment/course-session-enrollment/course-session.module#CourseSessionModule",
        data: {
          breadcrumb: "Course Session Enrollment",
          tittleInformation: "Enroll into Course Session",
        },
      },
      {
        path: "exam-enrollment",
        loadChildren:
          "@modules/enrollment/exam-enrollment/exam-enrollment.module#ExamEnrollmentModule",
        data: {
          breadcrumb: "Exam Enrollment",
          // breadcrumb: "Survey Enrollment",

          // tittleInformation: "Enroll into Survey",
          tittleInformation: "Enroll into Exam",
        },
      },
      {
        path: "exam-list",
        loadChildren:
          "@modules/enrollment/exam-list/exam-list.module#ExamListModule",
        data: {
          breadcrumb: "Exam List",
          // breadcrumb: "Survey Enrollment",

          // tittleInformation: "Enroll into Survey",
          tittleInformation: "Exam List",
        },
      },
      {
        path: "survey-enrollment",
        loadChildren:
          "@modules/enrollment/survey-enrollment/survey-enrollment.module#SurveyEnrollmentModule",
        data: {
          breadcrumb: "Survey Enrollment",
          tittleInformation: "Enroll into Survey",
        },
      },
      {
        path: "student-list",
        loadChildren:
          "@modules/manage-student/student-list/student-list.module#StudentListModule",
        canActivate: [StudentGuard],
        data: {
          breadcrumb: "Student List",
          // breadcrumb: "Employee List",
          tittleInformation: "List of all Students",
          // tittleInformation: "List of all Employees",
        },
      },

      {
        path: "student-enrolled",
        loadChildren:
          "@modules/manage-student/student-enrolled/student-enrolled.module#StudentEnrolledModule",
        data: {
          breadcrumb: "Student Enrolled",
          tittleInformation: "List of Students enrolled",
        },
      },

      {
        path: "manage-teacher",
        loadChildren:
          "@modules/manage-teacher/manage-teacher.module#ManageTeacherModule",
        canActivate: [StudentGuard, TeacherGuard],
        data: {
          breadcrumb: "Manage Teacher",
          tittleInformation: "List of Students enrolled",
        },
      },

      {
        path: "take-exam",
        loadChildren: "@modules/take-exam/take-exam.module#TakeExamModule",
        data: {
          breadcrumb: "Take Test",
          // breadcrumb: "Take Survey",
          tittleInformation: "Take your test",
          // tittleInformation: "Take your Survey",
        },
      },

      {
        path: "report",
        loadChildren: "@modules/report/report.module#ReportModule",
        data: {
          breadcrumb: "Reports",
          tittleInformation: "View report of all users",
        },
      },

      {
        path: "user-profile",
        loadChildren:
          "@modules/user-profile/user-profile.module#UserProfileModule",
        data: {
          breadcrumb: "User Profile",
          tittleInformation: "View and edit your Information",
        },
      },
      {
        path: "settings",
        loadChildren: "@modules/settings/settings.module#SettingsModule",
        canActivate: [StudentGuard, TeacherGuard],
        data: {
          breadcrumb: "User settings",
          tittleInformation: "change your setting and preferences",
        },
      },
      {
        path: "admissions",
        loadChildren: "@modules/admissions/admissions.module#AdmissionsModule",
        canActivate: [StudentGuard, TeacherGuard],
        data: {
          breadcrumb: "Admissions",
          tittleInformation: "list of admissions",
        },
      },
      {
        path: "payments",
        loadChildren: "@modules/payment/payment.module#PaymentModule",
        // canActivate: [StudentGuard, TeacherGuard],
        data: {
          breadcrumb: "Payments",
          tittleInformation: "list of payments",
        },
      },
      {
        path: "feedbacks",
        loadChildren: "@modules/feedbacks/feedback.module#FeedbackModule",
        canActivate: [StudentGuard, TeacherGuard],
        data: {
          breadcrumb: "Feedbacks",
          tittleInformation: "list of feebacks",
        },
      },
      {
        path: "sms",
        loadChildren: "@modules/sms/sms.module#SmsModule",
        canActivate: [StudentGuard, TeacherGuard],
        data: {
          breadcrumb: "SMS",
          tittleInformation: "SMS",
        },
      },
      {
        path: "forum",
        loadChildren:
          "@modules/discussion forum/discussion-forum.module#DiscussionForumModule",

        data: {
          breadcrumb: "Discussion Forum",
          tittleInformation:
            "Discuss about questions and exam if you are unclear",
        },
      },
      {
        path: "notice",
        loadChildren: "@modules/notice/notice.module#NoticeModule",
        canActivate: [StudentGuard, TeacherGuard],
        data: {
          breadcrumb: "Notice",
          tittleInformation: "Add notice for displaying in landing page",
        },
      },
      {
        path: "advertisement",
        loadChildren:
          "@modules/advertisement/advertisement.module#AdvertisementModule",
        canActivate: [StudentGuard, TeacherGuard],
        data: {
          breadcrumb: "Advertisement",
          tittleInformation: "Add advertisement for displaying in landing page",
        },
      },
      {
        path: "syllabus",
        loadChildren: "@modules/syllabus/syllabus.module#SyllabusModule",
        canActivate: [StudentGuard, TeacherGuard],
        data: {
          breadcrumb: "Syllabus",
          tittleInformation: "Add advertisement for displaying in landing page",
        },
      },
      {
        path: "course-syllabus",
        loadChildren: "@modules/course-syllabus/syllabus.module#SyllabusModule",
        data: {
          breadcrumb: "Syllabus",
          tittleInformation: "Add advertisement for displaying in landing page",
        },
      },
      {
        path: "user-manual",
        loadChildren:
          "@modules/manual/course-attachment.module#CourseAttachmentModule",
        data: {
          breadcrumb: "Manual",
          tittleInformation: "Add advertisement for displaying in landing page",
        },
      },
      {
        path: "manual",
        loadChildren: "@modules/user-manual/manual.module#ManualModule",
        canActivate: [StudentGuard, TeacherGuard],
        data: {
          breadcrumb: "User Manual",
          tittleInformation: "Add advertisement for displaying in landing page",
        },
      },
      {
        path: "error",
        loadChildren: "@modules/error-page/error-page.module#ErrorPageModule",
        data: {
          breadcrumb: "Error",
          tittleInformation: "Soory we got error",
        },
      },
      {
        path: "**",
        loadChildren: "@modules/error-page/error-page.module#ErrorPageModule",
        data: {
          breadcrumb: "Error",
          tittleInformation: "Soory we got error",
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
