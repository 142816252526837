import { LocalStorageService } from "./../../../shared/services/local-storage/local-storage.service";
import { FormGroup, FormBuilder } from "@angular/forms";
// import { LocalStorageService } from "@app/shared/services/local-storage/local-storage.service";
import { LayoutService } from "./../services/layout.service";
import { FooterComponent } from "./footer/footer.component";
import { ConnectionService } from "ng-connection-service";
import {
  Component,
  OnInit,
  AfterViewChecked,
  ViewChild,
  ElementRef,
  ɵɵpureFunction2,
} from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Subscription } from "rxjs";
import { BreadcrumbsService } from "ng6-breadcrumbs";
import { StudentListService } from "@app/modules/manage-student/student-list/services/student-list.service";

@Component({
  selector: "app-admin-panel",
  templateUrl: "./admin-panel.component.html",
  styleUrls: ["./admin-panel.component.scss"],
})
export class AdminPanelComponent implements OnInit {
  // entryComponent = FooterComponent;
  titleHeading: any;
  titleDescription: any;
  subscription: Subscription;
  user_id = this.localStorageService.getLocalStorageItem("user_id");
  @ViewChild("scrollMe", { static: false })
  private myScrollContainer: ElementRef;
  user_type = this.localStorageService.getLocalStorageItem("user_type");
  level1User = this.localStorageService.getLocalStorageItem("level1User");
  level2User = this.localStorageService.getLocalStorageItem("level2User");
  userDetail: any;
  constructor(
    private router: Router,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute,
    private layoutService: LayoutService,
    private localStorageService: LocalStorageService,
    private fb: FormBuilder,
    private breadcrumbsService: BreadcrumbsService,
    private connectionService: ConnectionService,
    private studentListService: StudentListService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let child = this.activatedRoute.firstChild;
        this.titleHeading = this.checkTitleHeading(
          child.snapshot.data.breadcrumb
        );
        this.titleDescription = child.snapshot.data.tittleInformation;
      }
    });
    // this.checkInternet();
  }

  checkTitleHeading(data) {
    if (data == "Student Dashboard") {
      return this.level2User + " Dashboard";
    } else if (data == "Teacher Dashboard") {
      return this.level1User + " Dashboard";
    } else if (data == "Student List") {
      return this.level2User + " List";
    } else if (data == "Manage Teacher") {
      return "Manage " + this.level1User;
    } else {
      return data;
    }
  }

  // isConnected = true;
  // noInternetConnection: boolean;

  // checkInternet() {
  //   this.connectionService.monitor().subscribe((isConnected) => {
  //     console.log(isConnected);
  //     this.isConnected = isConnected;
  //     if (this.isConnected) {
  //       this.noInternetConnection = false;
  //     } else {
  //       this.noInternetConnection = true;
  //     }
  //   });
  // }

  ngOnInit() {
    this.getStudentList();
  }
  getStudentList(): void {
    let body = {
      mentor_id: "",
      student_id: this.user_id,
      page: 1,
      limit: "",
      sort: 1,
      sort_field: "name",
      fields: [
        {
          field: "id",
          operator: "matches",
          value: this.user_id,
        },
      ],
    };

    this.studentListService.getStudentList(body).subscribe(
      (response) => {
        if (response.status == "success") {
          this.userDetail = response.data[0];
          this.localStorageService.setLocalStorageItem(
            "paid_unpaid",
            this.userDetail.paid_unpaid
          );
          return;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  logout(): void {
    this.cookieService.deleteAll();
    this.localStorageService.clearLocalStorage();
    this.router.navigate(["/home"]);
    window.location.reload();
  }
}
