import { SessionExpireInterceptor } from "./core/interceptor/sessionExpire.interceptor";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { CoreModule } from "./core/core.module";

import { CookieService } from "ngx-cookie-service";
import { HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { GridModule } from "@progress/kendo-angular-grid";
import { FacebookModule } from "ngx-facebook";
// import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

// interceptors
// import { TokenInterceptor } from "./core/interceptors/token.interceptor";
import { UploadInterceptor } from "./core/interceptor/fileUpload.interceptor";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { PDFExportModule } from "@progress/kendo-angular-pdf-export";
import { DatePipe } from "@angular/common";
import { PagerModule } from "@progress/kendo-angular-pager";
import { ConfirmEqualValidatorDirective } from "./shared/directives/confirm-equal-validator.directive";



@NgModule({
  declarations: [AppComponent, ConfirmEqualValidatorDirective],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    GridModule,
    LayoutModule,
    PDFExportModule,
    FacebookModule.forRoot(),
    PagerModule,
  
    // MatProgressSpinnerModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: UploadInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionExpireInterceptor,
      multi: true,
    },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
