import { ToastrMessageService } from "./../../shared/services/toastr-message/toastr-message.service";
import { LocalStorageService } from "./../../shared/services/local-storage/local-storage.service";
import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class TeacherGuard implements CanActivate {
  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private ToastrMessageService: ToastrMessageService
  ) {}

  user_type = this.localStorageService.getLocalStorageItem("user_type");

  canActivate(): boolean {
    if (this.user_type == "teacher") {
      this.ToastrMessageService.showError("Permission Denied");
      this.router.navigate(["/dashboard/teacher"]);

      return false;
    } else return true;

    // const token = this.cookieService.get("token");
    // if (!token) {
    //   this.router.navigate(["/home"]);
    //   return false;
    // }
  }
}
