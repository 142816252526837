import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoaderInterceptor } from "./../interceptor/loader.interceptor";
import { LoaderService } from "./service/loader.service";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { LoaderComponent } from "./component/loader.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule],
  exports: [LoaderComponent],
  declarations: [LoaderComponent],
  providers: [
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
})
export class LoaderModule {}
