import { Injectable } from "@angular/core";

import { HttpParams, HttpHeaders, HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { LocalStorageService } from "@app/shared/services/local-storage/local-storage.service";
import { CookieService } from "ngx-cookie-service";
import { environment } from "@env/environment";
@Injectable({
  providedIn: "root",
})
export class StudentDashboardService {
  baseIp = environment.online_test_baseIP;
  apiPrefix = environment.online_test_apiPrefix;
  access_token = this.cookieService.get("token");

  constructor(
    private localStorageService: LocalStorageService,
    private cookieService: CookieService,
    private httpClient: HttpClient
  ) {}

  getStudentDashboardDetail(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "access-token": this.access_token,
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}student/student-dashboard`,
      body,
      options
    );
  }

  // get course attachment
  getLrm(body): Observable<any> {
    if (this.access_token == null) {
      this.access_token = this.cookieService.get("token");
    }
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "access-token": this.access_token,
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}lrm/lrm-search`,
      body,
      options
    );
  }

  getExamEnrolled(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "access-token": this.access_token,
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}exam-enroll/get-exam-enrolled`,
      body,
      options
    );
  }

  generateFontSign(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "api-key": "KEY012345",
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}signature/generate-font-sign`,
      body,
      options
    );
  }

  getFontList(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "api-key": "KEY012345"
      }),
    };

    return this.httpClient.get(
      `${this.baseIp}${this.apiPrefix}signature/font-list`,
      options
    );
  }

}
