import { ToastrMessageService } from "@app/shared/services/toastr-message/toastr-message.service";
import { GlobalService } from "@app/shared/services/global/global.service";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoginService } from "@app/modules/auth/login/services/login.service";
import { Observable, of } from "rxjs";
import { tap, catchError, map } from "rxjs/operators";
@Injectable()
export class SessionExpireInterceptor implements HttpInterceptor {
  constructor(
    private loginService: LoginService,
    private globalService: GlobalService,
    private toastrMessageService: ToastrMessageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse) {
          if (evt.body.status == "failure" && evt.body.statusCode == 422) {
            this.toastrMessageService.showError(
              "Your session has been expired"
            );
            this.globalService.logout();
          }
        }
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          console.log(err);
        }
        return of(err);
      })
    );
  }
}
